<template>
	<div class="section_body section_join3">
		<div class="section_content">
			<div class="wrap_full_content">
				<p class="txt_big">견적서가 발송되었습니다.</p>
				<p class="desc">
					입력하신 메일 주소로 견적서를 확인해주세요.<br />
					문의 : 1577-3019 / daouoffice@daou.com<br /><br />
				</p>
				<p class="desc" v-if="this.$store.getters.isSelecteFreeTrialItem">
					결제금액에는 {{ this.$store.getters.isSelecteFreeTrialItem.name }}
					{{ this.$store.getters.isSelecteFreeTrialItem.item.freeTrialPolicyModel.usagePeriod }}개월 무료체험 후
					유료전환시 결제예상금액이 포함되어있습니다.<br />
					<span class="em"
						>※하단의 유료전환시 결제예상금액은 무료체험 중 멤버 및 옵션상품 추가에 따라 변동될 수 있습니다.</span
					>
				</p>
			</div>
			<div class="com_form_large form_join3">
				<order-info v-if="!this.$store.getters.isSelecteFreeTrialItem"></order-info>
				<real-order-info v-if="this.$store.getters.isSelecteFreeTrialItem"></real-order-info>
				<step-footer :stepBtnInfo="stepBtnInfo" :actionBeforeMove="clearAll"></step-footer>
			</div>
		</div>
	</div>
</template>

<script>
import OrderInfo from '@/components/apply/resultInfo/OrderInfo.vue';
import RealOrderInfo from '@/components/apply/resultInfo/RealOrderInfo.vue';
import StepFooter from '@/components/common/StepFooter.vue';
import { estimate } from '@/views/mixinsEstimate.js';
export default {
	mixins: [estimate],
	data() {
		return {
			stepBtnInfo: {
				prevLink: '/apply/estimate/step1',
				next: '완료',
				nextLink: '/apply/step1',
			},
		};
	},
	components: {
		OrderInfo,
		RealOrderInfo,
		StepFooter,
	},
	methods: {
		clearAll() {
			this.$store.commit('clearApplyServiceInfo');
			return true;
		},
	},
	created() {
		window.scrollTo(0, 0);
		this.sendNewOrderEstimate();
	},
};
</script>

<style></style>
