export const estimate = {
	// 견적서 발송을 위한 model 변환
	methods: {
		numberWithCommas(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		getEstimateTermsAgree(type) {
			const estimateTerms = this.$store.getters.getEstimateTermsAgreementInfo;
			return estimateTerms.filter((o) => o.termsType == type)[0].approved;
		},
		async sendNewOrderEstimate() {
			const estimateModel = {
				customer: {}, // 고객사
				baseInfo: {},
				totAmount: 0, // 결제 총 금액(부가세 포함)
				monthlyBaseChannelTotAmount: 0, // 기본 서비스 사용료(월) 소계
				monthlyAddonChannelTotAmount: 0, // 부가 서비스 사용료(월) 소계
				onceAmountTotAmount: 0, // 1회성 요금 소계
				expirationDate: 0, // 서비스 만료일
				monthlyBaseChannelAmounts: [], // 기본 서비스 사용료 (월) -- 기본료, 사용자 추가
				monthlyAddonChannelAmounts: [], // 부가 서비스 사용료 (월) -- 기본료, 사용자 추가
				onceAmounts: [], // 1회성 요금 (서비스 설치비 + 1회성 옵션 상품)
				freeTrialRealAmounts: [],
				selectFreeTrial: false,
				termsOfPrivacy: this.getEstimateTermsAgree('TERMS_OF_PRIVACY'),
				termsOfMarketing: this.getEstimateTermsAgree('TERMS_OF_MARKETING'),
			};
			const customer = await this.$store.getters.getEstimateAllCompanyInfo;
			const today = this.$moment().format('YYYY-MM-DD');

			estimateModel.customer = {
				customer: {
					companyInfo: {
						corporateName: customer.companyInfo.companyName,
					},
				},
				siteName: customer.companyInfo.companyName,
				managerName: customer.companyManagerInfo.name,
				email: customer.companyManagerInfo.email,
				tel: customer.companyManagerInfo.phone,
				estimateDate: today,
				estimateValidDate: '견적일로부터 7일 이내',
			};

			estimateModel.totAmount = this.$store.getters.getSummuryPrices.finalPayPrice;
			const selectFreeTrial = this.$store.getters.isSelecteFreeTrialItem ? true : false;

			if (selectFreeTrial) {
				estimateModel.freeTrialRealTotAmount =
					parseInt(this.$store.getters.getSummuryOriginPrices.finalPayPrice) - parseInt(estimateModel.totAmount);
			}
			// 1. 기본 서비스 사용료(월)
			const basicItem = await this.$store.getters.getBasicItemSelected[0];

			estimateModel.baseInfo = {
				serviceTypeDesc: basicItem.serviceCategoryModel.name,
				serviceType: basicItem.serviceCategoryModel.serviceType,
				employeeNum: customer.companyInfo.employeeNum,
				totUserCount: basicItem.userCnt,
				totQuota: await this.$store.getters.getTotQuota,
				usePeriod: basicItem.usePeriod,
			};

			// 1) 월과금 기본료
			const baseServiceMonthly = {
				type: '기본',
				serviceName: basicItem.name,
				amountList: [],
				sumFinalAmount: 0,
				amountListCount: 0,
			};

			const baseOptionMonthly = {
				type: '옵션',
				serviceName: basicItem.name,
				amountList: [],
				sumFinalAmount: 0,
				amountListCount: 0,
			};
			let membershipFeeDisc = [];
			let defaultFeeDisc = []; // 기본료
			let addUserFeeDisc = []; // 사용자추가
			let longtermDisc = []; // 장기선납
			if (basicItem.discountPolicyModels.length > 0) {
				membershipFeeDisc = basicItem.discountPolicyModels.filter(
					(disc) => disc.itemType == 'MEMBERSHIP_FEE' && disc.useNew,
				);

				defaultFeeDisc = basicItem.discountPolicyModels.filter((disc) => disc.itemType == 'DEFAULT_FEE' && disc.useNew);
				addUserFeeDisc = basicItem.discountPolicyModels.filter((disc) => disc.itemType == 'ADD_USER' && disc.useNew);
			}
			if (basicItem.periodPolicyModel.useLongTermPrepaymentDiscount) {
				longtermDisc = basicItem.periodPolicyModel.discountRate.filter((disc) => disc.month == basicItem.usePeriod);
			}
			const defaultVolumeAndUser =
				basicItem.defaultFeePolicyModel.defaultVolume > 0 || basicItem.defaultFeePolicyModel.defaultUserNum > 0
					? ' (' +
					  (basicItem.defaultFeePolicyModel.defaultUserNum > 0
							? basicItem.defaultFeePolicyModel.defaultUserNum + '명'
							: '') +
					  (basicItem.defaultFeePolicyModel.defaultVolume > 0 && basicItem.defaultFeePolicyModel.defaultUserNum
							? '/'
							: '') +
					  (basicItem.defaultFeePolicyModel.defaultVolume > 0
							? basicItem.defaultFeePolicyModel.defaultVolume + 'GB'
							: '') +
					  ')'
					: '';

			if (basicItem.defaultFeePolicyModel && basicItem.defaultFeePolicyModel.usePolicy) {
				const amountPerItemModel = {
					serviceName: basicItem.name,
					itemName: '기본 서비스 기본료' + defaultVolumeAndUser,
					price: this.numberWithCommas(basicItem.defaultFeePolicyModel.price),
					count: basicItem.usePeriod + '개월',
					discountAmount:
						(longtermDisc.length == 0 || (longtermDisc.length > 0 && longtermDisc[0].rate == 0)) &&
						defaultFeeDisc.length == 0
							? '-'
							: (longtermDisc.length > 0 && longtermDisc[0].rate > 0 ? longtermDisc[0].rate + '%' : '') +
							  (longtermDisc.length > 0 && longtermDisc[0].rate && defaultFeeDisc.length > 0 ? ', ' : '') +
							  (defaultFeeDisc.length > 0
									? defaultFeeDisc[0].num + (defaultFeeDisc[0].benefitType == 'RATE' ? '%' : '')
									: ''),
					finalAmount: Math.floor(basicItem.defaultFeeTotDiscountPrice / 10) * 10,
					priceType: basicItem.defaultFeePolicyModel.priceType,
				};
				baseServiceMonthly.sumFinalAmount += amountPerItemModel.finalAmount;
				baseServiceMonthly.amountList.push(amountPerItemModel);
			}

			// 2) 월과금 사용자 추가
			if (
				basicItem.addUserIntervalPolicyModel &&
				basicItem.addUserIntervalPolicyModel.usePolicy &&
				basicItem.addUserDiscountMatrix.length > 0
			) {
				const addVolume =
					basicItem.addUserIntervalPolicyModel.defaultVolumeForAddUser > 0
						? ' (1인 ' + basicItem.addUserIntervalPolicyModel.defaultVolumeForAddUser + 'GB 포함)'
						: '';
				await basicItem.addUserDiscountMatrix.forEach((obj) => {
					const amountPerItemModel = {
						serviceName: basicItem.name,
						itemName: '사용자 추가 ' + obj.interval + '인' + addVolume,
						price: this.numberWithCommas(obj.price),
						count: obj.discountCnt + '명',
						discountAmount:
							(longtermDisc.length == 0 || (longtermDisc.length > 0 && longtermDisc[0].rate == 0)) &&
							addUserFeeDisc.length == 0
								? '-'
								: (longtermDisc.length > 0 && longtermDisc[0].rate > 0 ? longtermDisc[0].rate + '%' : '') +
								  (longtermDisc.length > 0 && longtermDisc[0].rate && addUserFeeDisc.length > 0 ? ', ' : '') +
								  (addUserFeeDisc.length > 0
										? addUserFeeDisc[0].num + (addUserFeeDisc[0].benefitType == 'RATE' ? '%' : '')
										: ''),
						finalAmount: Math.floor(obj.totPrice / 10) * 10,
						priceType: 'FIXED',
					};
					baseServiceMonthly.sumFinalAmount += amountPerItemModel.finalAmount;
					baseServiceMonthly.amountList.push(amountPerItemModel);
				});
			}
			//  3) 월과금 옵션 상품
			if (basicItem.optionItemSelected.length > 0) {
				const monthlyOptionItem = basicItem.optionItemSelected.filter(
					(obj) => obj.channelItemSalesSectionModel.billingType == 'MONTHLY',
				);
				await monthlyOptionItem.forEach((obj) => {
					const amountPerItemModel = {
						serviceName: basicItem.name,
						itemName: obj.displaySectionModel.displayName,
						price: this.numberWithCommas(obj.channelItemSalesSectionModel.price),
						count: obj.orderCount + '개',
						discountAmount: '-',
						finalAmount: Math.floor(obj.finalPrice / 10) * 10,
						priceType: obj.channelItemSalesSectionModel.priceType,
					};

					baseOptionMonthly.sumFinalAmount += amountPerItemModel.finalAmount;
					baseOptionMonthly.amountList.push(amountPerItemModel);
				});
			}

			if (baseServiceMonthly.amountList.length > 0) {
				baseServiceMonthly.amountListCount = baseServiceMonthly.amountList.length;
				for (let seq = 0; seq < baseServiceMonthly.amountList.length; seq++) {
					baseServiceMonthly.amountList[seq]['seq'] = seq;
				}
				estimateModel.monthlyBaseChannelAmounts.push(baseServiceMonthly);
			}

			if (baseOptionMonthly.amountList.length > 0) {
				baseOptionMonthly.amountListCount = baseOptionMonthly.amountList.length;
				for (let seq = 0; seq < baseOptionMonthly.amountList.length; seq++) {
					baseOptionMonthly.amountList[seq]['seq'] = seq;
				}
				estimateModel.monthlyBaseChannelAmounts.push(baseOptionMonthly);
			}

			// 2. 부가 서비스 사용료(월)
			const addonItemList = await this.$store.getters.getAddonItemSelected;
			await addonItemList.forEach((addonItem) => {
				const isFreeTrial = addonItem.freeTrialPolicyModel && addonItem.freeTrialPolicyModel.usePolicy ? true : false;
				const addonServiceMonthly = {
					type: '부가',
					serviceName: addonItem.name,
					amountList: [],
					sumFinalAmount: 0,
					amountListCount: 0,
				};

				const addonOptionMonthly = {
					type: '옵션',
					serviceName: addonItem.name,
					amountList: [],
					sumFinalAmount: 0,
					amountListCount: 0,
				};
				let addonDefaultFeeDisc = []; // 기본료
				let addonAddUserFeeDisc = []; // 사용자추가
				let addonLongtermDisc = []; // 장기선납
				if (addonItem.discountPolicyModels.length > 0) {
					addonDefaultFeeDisc = addonItem.discountPolicyModels.filter(
						(disc) => disc.itemType == 'DEFAULT_FEE' && disc.useNew,
					);
					addonAddUserFeeDisc = addonItem.discountPolicyModels.filter(
						(disc) => disc.itemType == 'ADD_USER' && disc.useNew,
					);
				}

				if (addonItem.periodPolicyModel.useLongTermPrepaymentDiscount) {
					addonLongtermDisc = addonItem.periodPolicyModel.discountRate.filter(
						(disc) => disc.month == addonItem.usePeriod,
					);
				}

				// 1) 월과금 기본료
				if (addonItem.defaultFeePolicyModel && addonItem.defaultFeePolicyModel.usePolicy) {
					const amountPerItemModel = {
						serviceName: addonItem.name,
						itemName: '기본료',
						price: this.numberWithCommas(addonItem.defaultFeePolicyModel.price),
						count: addonItem.usePeriod + '개월',
						discountAmount:
							(addonLongtermDisc.length == 0 || (addonLongtermDisc.length > 0 && addonLongtermDisc[0].rate == 0)) &&
							addonDefaultFeeDisc.length == 0
								? '-'
								: (addonLongtermDisc.length > 0 && addonLongtermDisc[0].rate > 0
										? addonLongtermDisc[0].rate + '%'
										: '') +
								  (addonLongtermDisc.length > 0 && addonLongtermDisc[0].rate > 0 && addonDefaultFeeDisc.length > 0
										? ', '
										: '') +
								  (addonDefaultFeeDisc.length > 0
										? addonDefaultFeeDisc[0].num + (addonDefaultFeeDisc[0].benefitType == 'RATE' ? '%' : '')
										: ''),
						finalAmount: isFreeTrial
							? Math.floor(addonItem.defaultFeeTotDiscountPriceReal / 10) * 10
							: Math.floor(addonItem.defaultFeeTotDiscountPrice / 10) * 10,
						priceType: addonItem.defaultFeePolicyModel.priceType,
					};
					addonServiceMonthly.sumFinalAmount += amountPerItemModel.finalAmount;
					addonServiceMonthly.amountList.push(amountPerItemModel);
				}
				// 2. 월과금 사용자 추가
				if (
					addonItem.addUserIntervalPolicyModel &&
					addonItem.addUserIntervalPolicyModel.usePolicy &&
					addonItem.addUserDiscountMatrix.length > 0
				) {
					addonItem.addUserDiscountMatrix.forEach((obj) => {
						const amountPerItemModel = {
							serviceName: addonItem.name,
							itemName: '사용자 추가 ' + obj.interval + '인',
							price: this.numberWithCommas(obj.price),
							count: obj.discountCnt + '명',
							discountAmount:
								(addonLongtermDisc.length == 0 || (addonLongtermDisc.length > 0 && addonLongtermDisc[0].rate == 0)) &&
								addonAddUserFeeDisc.length == 0
									? '-'
									: (addonLongtermDisc.length > 0 && addonLongtermDisc[0].rate > 0
											? addonLongtermDisc[0].rate + '%'
											: '') +
									  (addonLongtermDisc.length > 0 && addonLongtermDisc[0].rate > 0 && addonAddUserFeeDisc.length > 0
											? ', '
											: '') +
									  (addonAddUserFeeDisc.length > 0
											? addonAddUserFeeDisc[0].num + (addonAddUserFeeDisc[0].benefitType == 'RATE' ? '%' : '')
											: ''),
							finalAmount: isFreeTrial ? Math.floor(obj.totPriceReal / 10) * 10 : Math.floor(obj.totPrice / 10) * 10,
							priceType: 'FIXED',
						};
						addonServiceMonthly.sumFinalAmount += amountPerItemModel.finalAmount;
						addonServiceMonthly.amountList.push(amountPerItemModel);
					});
				}

				// 3) 월과금 옵션 상품
				if (addonItem.optionItemSelected.length > 0) {
					const monthlyOptionItem = addonItem.optionItemSelected.filter(
						(obj) => obj.channelItemSalesSectionModel.billingType == 'MONTHLY',
					);
					monthlyOptionItem.forEach((obj) => {
						const amountPerItemModel = {
							serviceName: addonItem.name,
							itemName: obj.displaySectionModel.displayName,
							price: this.numberWithCommas(obj.channelItemSalesSectionModel.price),
							count: obj.orderCount + '개',
							discountAmount: '-',
							finalAmount: isFreeTrial ? obj.finalPriceReal : obj.finalPrice,
							priceType: obj.channelItemSalesSectionModel.priceType,
						};
						addonOptionMonthly.sumFinalAmount += amountPerItemModel.finalAmount;
						addonOptionMonthly.amountList.push(amountPerItemModel);
					});
				}

				if (addonServiceMonthly.amountList.length > 0) {
					addonServiceMonthly.amountListCount = addonServiceMonthly.amountList.length;
					for (let seq = 0; seq < addonServiceMonthly.amountList.length; seq++) {
						addonServiceMonthly.amountList[seq]['seq'] = seq;
					}
					if (isFreeTrial) {
						let idx = -1;

						if (estimateModel.freeTrialRealAmounts.length > 0) {
							idx = estimateModel.freeTrialRealAmounts.findIndex(
								(obj) => obj.serviceName == addonServiceMonthly.serviceName,
							);
						}
						if (idx > -1) {
							const startIdx = estimateModel.freeTrialRealAmounts[idx].amountList.length;
							for (let seq = 0; seq < addonServiceMonthly.amountList.length; seq++) {
								const obj = addonServiceMonthly.amountList[seq];
								obj.seq = startIdx + seq;
								estimateModel.freeTrialRealAmounts[idx].amountList.push(obj);
							}
						} else {
							estimateModel.freeTrialRealAmounts.push(addonServiceMonthly);
						}
					} else {
						estimateModel.monthlyAddonChannelAmounts.push(addonServiceMonthly);
					}
				}

				if (addonOptionMonthly.amountList.length > 0) {
					addonOptionMonthly.amountListCount = addonOptionMonthly.amountList.length;
					for (let seq = 0; seq < addonOptionMonthly.amountList.length; seq++) {
						addonOptionMonthly.amountList[seq]['seq'] = seq;
					}
					if (isFreeTrial) {
						let idx = -1;
						if (estimateModel.freeTrialRealAmounts.length > 0) {
							idx = estimateModel.freeTrialRealAmounts.findIndex(
								(obj) => obj.serviceName == addonOptionMonthly.serviceName,
							);
						}
						if (idx > -1) {
							const startIdx = estimateModel.freeTrialRealAmounts[idx].amountList.length;
							for (let seq = 0; seq < addonOptionMonthly.amountList.length; seq++) {
								const obj = addonOptionMonthly.amountList[seq];
								obj.seq = startIdx + seq;
								estimateModel.freeTrialRealAmounts[idx].amountList.push(obj);
							}
						} else {
							estimateModel.freeTrialRealAmounts.push(addonOptionMonthly);
						}
					} else {
						estimateModel.monthlyAddonChannelAmounts.push(addonOptionMonthly);
					}
				}
			});

			//4. 1회성
			const baseOnce = {
				type: '기본',
				serviceName: basicItem.name,
				amountList: [],
				sumFinalAmount: 0,
				amountListCount: 0,
			};

			if (basicItem.membershipFeePolicyModel && basicItem.membershipFeePolicyModel.usePolicy) {
				const amountPerItemModel = {
					serviceName: basicItem.name,
					itemName: '기본 서비스 설치비',
					price: this.numberWithCommas(basicItem.membershipFeePolicyModel.price),
					count: '1회',
					discountAmount:
						membershipFeeDisc.length > 0
							? membershipFeeDisc[0].num + (membershipFeeDisc[0].benefitType == 'RATE' ? '%' : '')
							: '-',
					finalAmount: Math.floor(basicItem.membershipFeeDiscountPrice / 10) * 10,
					priceType: basicItem.membershipFeePolicyModel.priceType,
				};
				baseOnce.sumFinalAmount += amountPerItemModel.finalAmount;
				baseOnce.amountList.push(amountPerItemModel);
			}

			if (basicItem.optionItemSelected.length > 0) {
				const onceOptionItem = basicItem.optionItemSelected.filter(
					(obj) => obj.channelItemSalesSectionModel.billingType == 'ONCE',
				);
				await onceOptionItem.forEach((obj) => {
					const amountPerItemModel = {
						serviceName: basicItem.name,
						itemName: obj.displaySectionModel.displayName,
						price: this.numberWithCommas(obj.channelItemSalesSectionModel.price),
						count: obj.orderCount + '개',
						discountAmount: '-',
						finalAmount: Math.floor(obj.finalPrice / 10) * 10,
						priceType: obj.channelItemSalesSectionModel.priceType,
					};
					baseOnce.sumFinalAmount += amountPerItemModel.finalAmount;
					baseOnce.amountList.push(amountPerItemModel);
				});
			}
			await addonItemList.forEach((addonItem) => {
				const isFreeTrial = addonItem.freeTrialPolicyModel && addonItem.freeTrialPolicyModel.usePolicy ? true : false;

				const addonOnce = {
					type: '부가',
					serviceName: addonItem.name,
					amountList: [],
					sumFinalAmount: 0,
					amountListCount: 0,
				};

				let addonMembershipFeeDisc = []; // 설치비
				if (addonItem.discountPolicyModels.length > 0) {
					addonMembershipFeeDisc = addonItem.discountPolicyModels.filter(
						(disc) => disc.itemType == 'MEMBERSHIP_FEE' && disc.useNew,
					);
				}

				if (addonItem.membershipFeePolicyModel && addonItem.membershipFeePolicyModel.usePolicy) {
					const amountPerItemModel = {
						serviceName: addonItem.name,
						itemName: '부가 서비스 설치비',
						price: this.numberWithCommas(addonItem.membershipFeePolicyModel.price),
						count: '1회',
						discountAmount:
							addonMembershipFeeDisc.length > 0
								? addonMembershipFeeDisc[0].num + (addonMembershipFeeDisc[0].benefitType == 'RATE' ? '%' : '')
								: '-',
						finalAmount: isFreeTrial
							? Math.floor(addonItem.membershipFeeDiscountPriceReal / 10) * 10
							: Math.floor(addonItem.membershipFeeDiscountPrice / 10) * 10,
						priceType: addonItem.membershipFeePolicyModel.priceType,
					};
					addonOnce.sumFinalAmount += amountPerItemModel.finalAmount;
					addonOnce.amountList.push(amountPerItemModel);
				}
				if (addonItem.optionItemSelected.length > 0) {
					const onceOptionItem = addonItem.optionItemSelected.filter(
						(obj) => obj.channelItemSalesSectionModel.billingType == 'ONCE',
					);
					onceOptionItem.forEach((obj) => {
						const amountPerItemModel = {
							serviceName: addonItem.name,
							itemName: obj.displaySectionModel.displayName,
							price: this.numberWithCommas(obj.channelItemSalesSectionModel.price),
							count: obj.orderCount + '개',
							discountAmount: '-',
							finalAmount: isFreeTrial
								? Math.floor(obj.finalPriceReal / 10) * 10
								: Math.floor(obj.finalPrice / 10) * 10,
							priceType: obj.channelItemSalesSectionModel.priceType,
						};
						addonOnce.sumFinalAmount += amountPerItemModel.finalAmount;
						addonOnce.amountList.push(amountPerItemModel);
					});
				}

				if (addonOnce.amountList.length > 0) {
					addonOnce.amountListCount = addonOnce.amountList.length;
					for (let seq = 0; seq < addonOnce.amountList.length; seq++) {
						addonOnce.amountList[seq]['seq'] = seq;
					}
					if (isFreeTrial) {
						let idx = -1;
						if (estimateModel.freeTrialRealAmounts.length > 0) {
							idx = estimateModel.freeTrialRealAmounts.findIndex((obj) => obj.serviceName == addonOnce.serviceName);
						}
						if (idx > -1) {
							const startIdx = estimateModel.freeTrialRealAmounts[idx].amountList.length;
							for (let seq = 0; seq < addonOnce.amountList.length; seq++) {
								const obj = addonOnce.amountList[seq];
								obj.seq = startIdx + seq;
								estimateModel.freeTrialRealAmounts[idx].amountList.push(obj);
							}
						} else {
							estimateModel.freeTrialRealAmounts.push(addonOnce);
						}
					} else {
						estimateModel.onceAmounts.push(addonOnce);
					}
				}
			});

			if (baseOnce.amountList.length > 0) {
				baseOnce.amountListCount = baseOnce.amountList.length;
				for (let seq = 0; seq < baseOnce.amountList.length; seq++) {
					baseOnce.amountList[seq]['seq'] = seq;
				}
				estimateModel.onceAmounts.push(baseOnce);
			}

			if (estimateModel.onceAmounts.length > 0) {
				estimateModel.onceAmounts.sort((a, b) => {
					if (a.type > b.type) return 1;
					if (a.type === b.type) return 0;
					if (a.type < b.type) return -1;
				});
			}

			await estimateModel.freeTrialRealAmounts.forEach((obj) => (obj.amountListCount = obj.amountList.length));
			await this.$store.dispatch('SENDNEWORDERESTIMATE', estimateModel);
		},
	},
};
