<template>
	<div>
		<!--@멤버십-->
		<div class="com_box_membership">
			<h1>
				{{ this.$store.getters.getBasicItemSelected[0].serviceCategoryModel.name }}
				<!--<span class="txt">{{
					$constants.serviceTypeDisplay[
						this.$store.getters.getBasicItemSelected[0].serviceCategoryModel
							.serviceType
					]
				}}</span>-->
			</h1>
			<span class="txt sub">Membership</span>
			<span class="com_tag">
				<span class="txt">사용자{{ this.$store.getters.getBasicItemSelected[0].userCnt }}인</span>

				<span class="txt">{{ this.$store.getters.getBasicItemSelected[0].usePeriod }}개월 </span>
			</span>
		</div>

		<!--@결제금액-->
		<div class="wrap_form">
			<div class="com_list">
				<table class="com_tb_data large tb_membership_payment">
					<thead>
						<tr>
							<th class="svc"><span class="txt">서비스명</span></th>
							<th class="item flex"><span class="txt">상품명</span></th>
							<th class="num"><span class="txt">수량</span></th>
							<th class="period"><span class="txt">사용기간</span></th>
							<th class="sum"><span class="txt">결제금액(원)</span></th>
						</tr>
					</thead>
					<tbody>
						<template v-for="service in tableData">
							<template v-for="(item, index) in service">
								<tr v-if="item.type == 'main'" :key="item.serviceName + '_' + item.itemName + '_main'">
									<td class="svc" v-if="index == 0" :rowspan="service.length">
										<span v-if="item.serviceName.indexOf('경리회계') > -1" class="ic_svc ic_accounting"></span>
										<span v-if="item.serviceName == '경비처리(무증빙)'" class="ic_svc ic_bizplay"></span>
										<span v-if="item.serviceName == 'TMSe 장비일체형 S'" class="ic_svc ic_tmse_s"></span>
										<span v-if="item.serviceName == 'TMSe 장비일체형 E'" class="ic_svc ic_tmse_e"></span>
										<span v-if="item.serviceName == 'TMSe 장비일체형 T'" class="ic_svc ic_tmse_share"></span>
										<span class="txt">{{ item.serviceName }}</span>

										<span class="com_tag" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
											<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료체험</span>
										</span>
									</td>
									<td class="item flex">
										<span class="txt">{{ item.itemName }}</span>
									</td>
									<td class="num">
										<span class="txt"> {{ item.count }}</span>
									</td>
									<td class="period">
										<span class="txt">{{ item.usePeriod }}</span>
									</td>
									<td class="sum">
										<span class="txt">{{ item.finalPrice | currency }}원</span><br />
										<span v-if="item.finalPrice < item.originPrice" class="txt line_through"
											>{{ item.originPrice | currency }}원</span
										>
									</td>
								</tr>
								<tr
									v-if="item.type == 'sub'"
									class="sub"
									:key="index + '_' + item.serviceName + '_' + item.itemName + '_sub'"
								>
									<!--@sub data-->
									<td class="item flex">
										<span class="ic_set ic_depth"></span>
										<span class="txt">{{ item.itemName }}</span>
									</td>
									<td class="num">{{ item.count }}</td>
									<td class="period"></td>
									<td class="sum">
										<span class="txt">{{ item.finalPrice | currency }}</span>
									</td>
								</tr>
							</template>
						</template>
					</tbody>

					<tbody class="summary">
						<tr>
							<td class="svc" colspan="4">
								<span class="txt">상품 금액</span>
							</td>
							<td class="sum">
								<span class="txt">{{ this.$store.getters.getSummuryOriginPrices.totOriginPrice | currency }}원</span>
							</td>
						</tr>
						<tr class="emphasise">
							<td class="svc" colspan="4">
								<span class="txt">할인 금액</span>
							</td>
							<td class="sum">
								<span class="txt">{{ this.$store.getters.getSummuryOriginPrices.totDiscountPrice | currency }}원</span>
							</td>
						</tr>
						<tr>
							<td class="svc" colspan="4">
								<span class="txt">상품 금액 - 할인 금액</span>
							</td>
							<td class="sum">
								<span class="txt">{{ this.$store.getters.getSummuryOriginPrices.totPayPrice | currency }}원</span>
							</td>
						</tr>
						<tr>
							<td class="svc" colspan="4">
								<span class="txt">부가세(10%)</span>
							</td>
							<td class="sum">
								<span class="txt">{{ this.$store.getters.getSummuryOriginPrices.surtaxPrice | currency }}원</span>
							</td>
						</tr>
					</tbody>
					<tfoot class="total">
						<tr>
							<td class="svc">
								<span class="txt">결제 금액(VAT포함)</span>
							</td>
							<td class="sum" colspan="4">
								<span class="txt">{{ this.$store.getters.getSummuryOriginPrices.finalPayPrice | currency }}원</span>
							</td>
						</tr>
						<tr>
							<td class="svc">
								<span class="txt">무료체험 서비스 유료전환시 결제예상금액(VAT포함)</span>
							</td>
							<td class="sum" colspan="4">
								<span class="txt"
									>{{
										calcMinus(
											this.$store.getters.getSummuryOriginPrices.finalPayPrice,
											this.$store.getters.getSummuryPrices.finalPayPrice,
										) | currency
									}}원</span
								>
							</td>
						</tr>
						<tr>
							<td class="svc">
								<span class="txt">무료체험 서비스 결제예상금액을 제외한 결제 금액(VAT포함)</span>
							</td>
							<td class="sum" colspan="4">
								<span class="txt">{{ this.$store.getters.getSummuryPrices.finalPayPrice | currency }}원</span>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableData: [],
		};
	},
	async created() {
		this.tableData = await this.setTable();
	},
	methods: {
		calcMinus(val1, val2) {
			return parseInt(val1) - parseInt(val2);
		},
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		async setTable() {
			var table = [];
			let services = [];
			await this.$store.getters.getBasicItemSelected.reduce((previous, baseChannel) => {
				return previous.then(async () => {
					baseChannel['type'] = 'BASE';
					services.push(baseChannel);
				});
			}, Promise.resolve());

			await this.$store.getters.getAddonItemSelected.reduce((previous, addonChannel) => {
				return previous.then(async () => {
					addonChannel['type'] = 'ADDON';
					services.push(addonChannel);
				});
			}, Promise.resolve());

			await services.reduce((previous, service) => {
				return previous.then(async () => {
					let serviceTable = [];
					if (service.defaultFeeTotDiscountPrice !== undefined) {
						let row = [];
						row.serviceName = service.name;
						row.itemName = '기본료';
						row.count = service.usePeriod + '개월 ';
						row.freeTrialPolicyModel = service.freeTrialPolicyModel;

						if (service.freeTrialPolicyModel && service.freeTrialPolicyModel.usePolicy) {
							row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDateReal;
						} else {
							row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDate;
						}

						if (service.type == 'BASE') {
							row.finalPrice = service.defaultFeeTotDiscountPrice;
							row.originPrice = service.defaultFeeTotNormalPrice;
						} else if (service.type == 'ADDON') {
							row.finalPrice = service.defaultFeeTotDiscountPriceReal;
							row.originPrice = service.defaultFeeTotNormalPriceReal;
						}
						row.originPrice = service.defaultFeeTotNormalPrice;
						row.type = 'main';
						serviceTable.push(row);
					}

					if (service.addUserTotDiscountPrice !== undefined) {
						let row = [];
						row.serviceName = service.name;
						row.itemName = '사용자 추가';
						row.freeTrialPolicyModel = service.freeTrialPolicyModel;
						row.count = service.addUserCnt + '명';
						if (service.freeTrialPolicyModel && service.freeTrialPolicyModel.usePolicy) {
							row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDateReal;
						} else {
							row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDate;
						}
						if (service.type == 'BASE') {
							row.finalPrice = service.addUserTotDiscountPrice;
							row.originPrice = service.addUserTotNormalPrice;
						} else if (service.type == 'ADDON') {
							row.finalPrice = service.addUserTotDiscountPriceReal;
							row.originPrice = service.addUserTotNormalPriceReal;
						}
						row.type = 'main';
						serviceTable.push(row);

						if (service.addUserDiscountMatrix) {
							service.addUserDiscountMatrix.forEach((obj) => {
								let subRow = [];
								subRow.serviceName = service.name;
								subRow.itemName = obj.interval + '명 (구간)';
								subRow.count = obj.discountCnt + '명';
								subRow.usePeriod = '';
								if (service.type == 'BASE') {
									subRow.finalPrice = obj.totPrice + '원';
									subRow.originPrice = obj.totPrice + '원';
								} else if (service.type == 'ADDON') {
									subRow.finalPrice = obj.totPriceReal + '원';
									subRow.originPrice = obj.totPriceReal + '원';
								}
								subRow.type = 'sub';
								serviceTable.push(subRow);
							});
						}
					}
					if (service.membershipFeeDiscountPrice !== undefined) {
						let row = [];
						row.serviceName = service.name;
						row.itemName = '설치비';
						row.freeTrialPolicyModel = service.freeTrialPolicyModel;
						row.count = '1회';
						if (service.freeTrialPolicyModel && service.freeTrialPolicyModel.usePolicy) {
							row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDateReal;
						} else {
							row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDate;
						}

						if (service.type == 'BASE') {
							row.finalPrice = service.membershipFeeDiscountPrice;
							row.originPrice = service.membershipFeeNormalPrice;
						} else if (service.type == 'ADDON') {
							row.finalPrice = service.membershipFeeDiscountPriceReal;
							row.originPrice = service.membershipFeeNormalPriceReal;
						}
						row.type = 'main';
						serviceTable.push(row);
					}

					if (service.optionItemSelected && service.optionItemSelected.length > 0) {
						service.optionItemSelected.reduce((previousOption, currentOption) => {
							return previousOption.then(async () => {
								let row = [];
								row.serviceName = currentOption.name;
								row.itemName = currentOption.displaySectionModel.displayName;
								row.freeTrialPolicyModel = currentOption.freeTrialPolicyModel;
								row.count = currentOption.orderCount + '개';
								if (service.freeTrialPolicyModel && service.freeTrialPolicyModel.usePolicy) {
									row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDateReal;
								} else {
									row.usePeriod = service.usingDate.startDate + '~' + service.usingDate.endDate;
								}
								if (service.type == 'BASE') {
									row.finalPrice = currentOption.finalPrice;
									row.originPrice = currentOption.finalPrice;
								} else if (service.type == 'ADDON') {
									row.finalPrice = currentOption.finalPriceReal;
									row.originPrice = currentOption.finalPriceReal;
								}
								row.type = 'main';
								serviceTable.push(row);
							});
						}, Promise.resolve());
					}

					table.push(serviceTable);
				});
			}, Promise.resolve());
			return table;
		},
		isAutoExtensionFreeTrial(service) {
			if (
				service.freeTrialPolicyModel.usePolicy &&
				service.usingCount > 0 &&
				this.$store.getters.getUsingMembership.autoExtension
			) {
				return true;
			}

			return false;
		},
	},
};
</script>

<style></style>
